// user_destroy_request.js

document.addEventListener('DOMContentLoaded', () => {
  const form = document.querySelector('form[data-remote=true]')

  if (form) {
    form.addEventListener('ajax:success', (event) => {
      const [data, status, xhr] = event.detail
    })

    form.addEventListener('ajax:error', (event) => {
      const [data, status, xhr] = event.detail
      // エラー時の処理: エラーメッセージを表示
      const errorMessageElement = document.querySelector(
        '.ht-support-profile-modal__error'
      )
      if (errorMessageElement) {
        errorMessageElement.textContent =
          data || 'An error occurred. Please try again.'
      }
    })
  }
})

// modal.js

document.addEventListener('DOMContentLoaded', (event) => {
  // 全てのトリガーボタンを取得
  var buttons = document.querySelectorAll('[data-modal-target]')

  // 全てのトリガーボタンにクリックイベントを設定
  buttons.forEach(function (button) {
    button.onclick = function () {
      var modalId = this.getAttribute('data-modal-target')
      var modal = document.querySelector(modalId)
      if (modal) {
        modal.style.display = 'block'
      }
    }
  })

  // 全てのモーダルに閉じるイベントを設定
  var spans = document.getElementsByClassName('close')
  for (var i = 0; i < spans.length; i++) {
    spans[i].onclick = function () {
      var modal = this.closest('.modal')
      modal.style.display = 'none'
    }
  }

  // モーダル外をクリックして閉じる
  window.onclick = function (event) {
    if (event.target.classList.contains('modal')) {
      event.target.style.display = 'none'
    }
  }
})
